import React from 'react'
import { useRequest } from 'ahooks';
import { ApiPort } from '$ACTIONS/API';
import { fetchRequest } from '$CONFIG/Fatch.config';

const getURLs = () => fetchRequest(ApiPort.URLs, 'GET', null);
// const getURLs = () => new Promise(resolve => {
//     setTimeout(() => {
//         resolve({
//             "mainSiteUrl": "http://www.staging.betway178.biz/",
//             "appDownloadUrls": {
//               "AndroidNativeApp": "https://bway336.com/nsNgo",
//               "IosNativeApp": "https://bway336.com/nsNgo",
//               "AndroidIMApp": "https://68buzz.com/rvKuw",
//               "IosIMApp": "https://68buzz.com/rvKuw"
//             },
//             "liveChatUrl": "https://staging-app.bimariobot.com/kiwiberryapp/?",
//             "isSuccess": true,
//             "code": "QRP00001",
//             "message": "成功"
//           })
//     }, 2000)
// })

const useURLs = (options) => {
    return useRequest(getURLs, options);
}

export default useURLs;
