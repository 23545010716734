import HostConfig from '$CONFIG/Host.config'
export const CMSURL = HostConfig.Config.CMSURL;
export const HostApi = HostConfig.Config.HostApi;
export const QRHost = HostConfig.Config.QRHost;


export const ApiPort = {
	Banner1 : CMSURL + `/cms/qrp-m1-001-banners`,
	Banner2 : CMSURL + `/cms/qrp-m1-002-banners`,
	Banner3 : CMSURL + `/cms/qrp-m1-003-banners`,
	Banner4 : CMSURL + `/cms/qrp-m1-004-banners`,
	Banner5 : CMSURL + `/cms/qrp-m1-005-banners`,
	Banner6 : CMSURL + `/cms/qrp-m1-006-banners`,
	Banner7 : CMSURL + `/cms/qrp-m1-007-banners`,
	Banner8 : CMSURL + `/cms/qrp-m1-008-banners`,
	Banner9 : CMSURL + `/cms/qrp-m1-009-banners`,
	Banner10 : CMSURL + `/cms/qrp-m1-010-banners`,
	Banner11 : CMSURL + `/cms/qrp-m1-011-banners`,
	Banner12 : CMSURL + `/cms/qrp-m1-012-banners`,
	Banner13 : CMSURL + `/cms/qrp-m1-013-banners`,
	Banner14 : CMSURL + `/cms/qrp-m1-014-banners`,

	// 注册API
	Register: HostApi + `/api/QRP/Register`,
	// 登录
	Login : HostApi + `/api/QRP/Login` ,
	URLs: HostApi + `/api/QRP/URLs` ,
	QrpFields: HostApi + `/api/QRP/QrpFields` ,
	QRCode: QRHost + `/qr/qr-code` ,
}