/*
 * @Date: 2023-04-10 12:58:17
 * @LastEditors: simLarry
 * @LastEditTime: 2023-05-04 14:03:59
 * @FilePath: \P1-M1-QRP-CODE\config\Host.config.js
 */
let Config = {};

export const DomainURL       = 'https://qrpm1stag.betway178.biz';

export const STAGE_CMS_URL   = 'https://cmsapistag.betway178.biz'; // stage
//export const STAGE_CMS_URL   = 'https://cache.viptoss.com'; // stage
export const SL_CMS_URL      = 'https://cmsapisl.betway178.biz';   // SL
// export const LIVE_CMS_URL    = 'https://cmsapi.betway178.biz';     // LIVE
export const LIVE_CMS_URL    = 'https://cache.bw873ty.com'; // LIVE
export const Live_BAK_CMS_URL = 'https://cache.36bw87.com'; // BAK


export const FP_BK_URL      = 'https://shsbwkpfcn.funpo.com:2041/thirdparty-payment';

export const SL_HOST_URL_API       = 'https://p1-qrp-sl-awstk.fubnb.com'; //SL
export const STAGE_HOST_URL_API = 'https://p1-qrp-stage-awstk.fubnb.com'; //stage
export const LIVE_HOST_URL_API  = 'https://p1-qrp-live-awstk.fubnb.com'; // LIVE


export const STAGE_ENPOINT_URL = 'https://p1-qrp-stage-awstk.fubnb.com'; //stage
export const ENPOINT_URL = 'https://p1-qrp-live-awstk.fubnb.com'; //live
// 判断环境
if ( typeof global.location !== 'undefined' ){
	let LocalHost = global.location.host;
	let isHttps = 'https:' === document.location.protocol;
	let SLAPI = Boolean(
		[
			'qrpm1sl',
			// '127.0.0.1:8889',
			'localhost:8889'
		].find((v)=>global.location.href.includes(v))
	)

	// 测试环境
	const Devwhich = global.location.toString().includes("qrpm1stag") || global.location.href.includes('localhost:8889');
	if( Devwhich ){
		Config = {
			HostApi   : STAGE_HOST_URL_API,
			LocalHost : '',
			CMSURL    : STAGE_CMS_URL,
			ENDPOINT  : STAGE_ENPOINT_URL,
			QRHost	  : FP_BK_URL
		}
	}else if(SLAPI){
		// 判断是否在master 分支

		Config = {
			HostApi   : SL_HOST_URL_API,
			LocalHost : '',
			CMSURL    : SL_CMS_URL,
			ENDPOINT  : STAGE_ENPOINT_URL,
			QRHost	  : FP_BK_URL
		}
	}else{
		// 判断是否在SL
		// if ( LocalHost === '' )
		Config = {
			/* 灰度地址 */
			HostApi: LIVE_HOST_URL_API,
			LocalHost: (isHttps ? 'https://' : 'http://') + LocalHost + '/',
			CMSURL: LIVE_CMS_URL,
			ENDPOINT  : ENPOINT_URL,
			QRHost	  : FP_BK_URL
		};

	}


}

export default { Config };
