import React, { createContext, useContext, useState } from 'react';

export const AuthContext = createContext(null);

export const AuthContextProvider = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleLoginSuccess = () => {
        setIsLoggedIn(true);
    };

    return (
        <AuthContext.Provider
            // 記得提供 context 給 Provider
            value={{
                isLoggedIn,
                onLoginSuccess: handleLoginSuccess
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);