import App from 'next/app';
import React from 'react';
import { ConfigProvider } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { AuthContextProvider } from '$UTILS/hooks/useAuth';
import '$DATA/Less/merge.less';
import Head from 'next/head';
import useURLs from "$UTILS/hooks/useURLs";

function MyApp({ Component, pageProps }) {
  const { data } = useURLs({ ready: true });
  const pathname = typeof window !== 'undefined' ? window.location.pathname.split('/')[1] || 'm3-001' : 'm3-001';

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/static/images/favicon.ico" />
        <title>
          {pathname === 'restrict' ? 'betway restriction page' : 'Betway必威体育官网，亞洲第一體育平台，立即下载手机版APP。'}
        </title>
      </Head>
      <ConfigProvider>
        <NiceModal.Provider>
          <AuthContextProvider>
            <Component {...pageProps} data={data} />
          </AuthContextProvider>
        </NiceModal.Provider>
      </ConfigProvider>
    </>
  );
}

export default MyApp;